<template>
  <v-container fluid class="pa-0" style="height: 100%;">
    <!---- Here is the Official Map ------>

    <v-card
      class="pa-0 pt-2 d-none d-sm-flex d-sm-none d-md-flex"
      style="position: absolute; z-index: 1; right: 190px; top: 10px;"
    >
      <v-card-text>
        <v-container fluid class="px-7 pb-5 pt-0" style="height: 50px;">
          <v-row>
            <v-col cols="12" class="pa-0">
              <v-text-field
                dense
                id="searchPlace"
                append-icon="mdi-magnify"
              ></v-text-field>
              <div id="infowindow-content">
                <img src width="16" height="16" id="place-icon" />
                <span id="place-name" class="title"></span>
                <br />
                <span id="place-address"></span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <div class="row" ref="mapPage" style="margin: 0 auto;">
      <div
        style="height: 100px; width: 20%; position: absolute; z-index: 1; left: 0px; top: 0px; background-color: white; padding-top: 50px;"
        class="px-5"
      >
        <search-field @search="search" :title="'Search Outlet'"></search-field>
      </div>
      <div class="box">
        <div class="outlet-column">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="py-1 pl-2">
                <v-btn-toggle>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="filterOutlets('geozone')"
                      >
                        <v-icon class="white--text">mdi-scatter-plot</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter by Geozone</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="filterOutlets('geofence')"
                      >
                        <v-icon class="white--text">mdi-current-dc</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter by Geofence</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="filterOutlets('hotspot')"
                      >
                        <v-icon class="white--text">mdi-fire</v-icon>
                      </v-btn>
                    </template>
                    <span>Filter by Hotspot</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="py-1 pr-2">
                <v-btn-toggle class="float-right">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="filterOutlets('')"
                      >
                        <v-icon class="white--text">mdi-refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset Data</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-container v-if="loadingList">
              <v-row>
                <v-col cols="12">
                  <center>
                    <span class="primary--text">Loading...</span>
                  </center>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                  ></v-progress-linear>
                </v-col>
              </v-row>
            </v-container>
            <v-list v-else dense style="background-color: #F5F5F5;">
              <v-list-item-group color="primary">
                <v-list-item
                  v-for="(item, i) in itemsSearch"
                  :key="i"
                  style="border-bottom: 1px solid #E0E0E0;"
                  @click="clickMarker(item.id)"
                >
                  <v-list-item-content>
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-list-item-title v-on="on">{{
                          item.name ? item.name.toUpperCase() : "--"
                        }}</v-list-item-title>
                      </template>
                      <span>{{
                        item.name ? item.name.toUpperCase() : "--"
                      }}</span>
                    </v-tooltip>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <!-- <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            @click="openEnrolledVehicles(item)"
                            color="indigo"
                            icon
                            small
                          >
                            <v-icon>mdi-bus</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{
                        "Enroll Vehicle/s - " + item.name.toUpperCase()
                      }}</span> </v-tooltip
                    >&nbsp; -->
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <v-btn
                            @click="openEditOutlet(item)"
                            color="teal"
                            icon
                            small
                            v-on="on"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span
                        >Edit&nbsp;- &nbsp;{{
                          item.name ? item.name.toUpperCase() : "--"
                        }}</span
                      > </v-tooltip
                    >&nbsp;
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="openDeleteOutlet(item.id)"
                          color="red"
                          icon
                          small
                          v-on="on"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Delete&nbsp;- &nbsp;{{
                          item.name ? item.name.toUpperCase() : "--"
                        }}</span
                      >
                    </v-tooltip>
                    &nbsp;
                    <!-- <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="openAssignSubCompanyDialog(item.id)"
                          color="amber darken-1"
                          icon
                          small
                          v-on="on"
                        >
                          <v-icon>mdi-domain</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Assign Sub Company&nbsp;- &nbsp;{{
                          item.name.toUpperCase()
                        }}</span
                      >
                    </v-tooltip> -->
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
      </div>
      <div class="gmap-column">
        <official-map id="map" ref="map"></official-map>
      </div>
    </div>

    <!---- End of Official Map ------>

    <!---- Here is the List of Outlets ------>

    <!-- <fab-button
      :styleButton="'position: absolute; z-index: 1; left: 10px; top: 10px;'"
      @click="show ? hideOutletList() : openOutletList()"
      dark
      right
      xsmall
      :icon="'mdi-view-list'"
      :tooltipSpan="show ? 'Hide List of Outlets' : 'Show List of Outlets'"
    ></fab-button>

    <transition name="slide-fade">
      <v-card
        v-if="show"
        class="outlet-list-card"
        style="position: absolute; z-index: 1; left: 0.9%; top: 65px;"
      >
        <v-card-title class="pb-0 gradientColor">
          <search-field
            dark
            @search="search"
            :title="'Search Outlet'"
            :color="'white'"
          ></search-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="12" md="5" lg="5" xl="5" class="py-1 pl-2">
              <v-btn-toggle>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="filterOutlets('geozone')"
                    >
                      <v-icon class="white--text">mdi-scatter-plot</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter by Geozone</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="filterOutlets('geofence')"
                    >
                      <v-icon class="white--text">mdi-current-dc</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter by Geofence</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="filterOutlets('hotspot')"
                    >
                      <v-icon class="white--text">mdi-fire</v-icon>
                    </v-btn>
                  </template>
                  <span>Filter by Hotspot</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="py-1 pr-2">
              <v-btn-toggle class="float-right">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click="filterOutlets('')"
                    >
                      <v-icon class="white--text">mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Reset Data</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <div class="scrolling-wrapper">
          <v-container v-if="loadingList">
            <v-row>
              <v-col cols="12">
                <center>
                  <span class="primary--text">Loading...</span>
                </center>
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
          <v-list v-else dense style="background-color: #F5F5F5;">
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in itemsSearch"
                :key="i"
                style="border-bottom: 1px solid #E0E0E0;"
                @click="clickMarker(item.id)"
              >
                <v-list-item-content>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-list-item-title v-on="on">{{
                        item.name.toUpperCase()
                      }}</v-list-item-title>
                    </template>
                    <span>{{ item.name.toUpperCase() }}</span>
                  </v-tooltip>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click="openEnrolledVehicles(item)"
                          color="indigo"
                          icon
                          small
                        >
                          <v-icon>mdi-bus</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{
                      "Enroll Vehicle/s - " + item.name.toUpperCase()
                    }}</span> </v-tooltip
                  >&nbsp;
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          @click="openEditOutlet(item)"
                          color="teal"
                          icon
                          small
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{
                      "Edit" + " " + "-" + " " + item.name.toUpperCase()
                    }}</span> </v-tooltip
                  >&nbsp;
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="openDeleteOutlet(item.id)"
                        color="red"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Delete&nbsp;- &nbsp;{{ item.name.toUpperCase() }}</span
                    >
                  </v-tooltip>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card>
    </transition> -->

    <!----- End of the List of Outlets ----->

    <!---- Here is the Add Outlet ------>

    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-fab-transition>
          <v-btn
            v-if="showDrawnOutletButtons"
            style="position: absolute; z-index: 1; left: 10px; top: 10px;"
            @click="openAddOutlet()"
            class="addOutletButtonClass"
            dark
            fab
            x-small
            color="primary"
            v-on="on"
          >
            <v-icon>mdi-shape-polygon-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Add Outlet</span>
    </v-tooltip>

    <mini-dialog
      :dialog="addOutletDialog"
      :max-width="'80%'"
      @closeDialog="closeAddOutlet()"
      @confirmDialog="validateAddForm()"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >Outlet Details</span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-0">
        <v-container fluid class="pa-3">
          <v-row>
            <v-col
              class="scrolling-wrapper-two"
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <details-container
                :data="data"
                :geozoneStatus="geozoneStatus"
                :geofenceStatus="geofenceStatus"
                :hotspotStatus="hotspotStatus"
                ref="addDetailsContainer"
                @openSaveDialog="openConfirmAddOutlet()"
              ></details-container>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              order="first"
              order-sm="first"
              order-md="last"
              order-lg="last"
              order-xl="last"
              class="pb-0 pt-0 pr-0 heightMap"
              :class="{ 'pl-0': $vuetify.breakpoint.smAndDown }"
            >
              <official-map ref="previewAddOutletMap"></official-map>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </mini-dialog>

    <mini-dialog
      :dialog="confirmAddOutletDialog"
      :loading="confirmLoading"
      :confirmSave="true"
      @confirmDialog="saveAddOutlet()"
      @closeDialog="closeConfirmAddOutlet()"
    ></mini-dialog>

    <!---- End of the Add Outlet ------>

    <!---- Here is the Dialog for Edit Outlet ------>

    <mini-dialog
      :dialog="editOutletDialog"
      :max-width="'80%'"
      @closeDialog="closeEditOutlet()"
      @confirmDialog="validateEditForm()"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text">Edit Outlet</span>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-0">
        <v-container fluid class="pa-3">
          <v-row>
            <v-col
              class="scrolling-wrapper-two"
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
            >
              <details-container
                :data="data"
                :geozoneStatus="geozoneStatus"
                :geofenceStatus="geofenceStatus"
                :hotspotStatus="hotspotStatus"
                :idleThresholdStatus="idleThreshold"
                ref="editDetailsContainer"
                @openSaveDialog="openConfirmEditOutlet()"
              ></details-container>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-fab-transition>
                    <v-btn
                      @click="redoEditDrawnOutlet()"
                      class="primary float-right"
                      dark
                      fab
                      x-small
                      v-on="on"
                    >
                      <v-icon>mdi-redo</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </template>
                <span>Redo Drawn Outlet</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              order="first"
              order-sm="first"
              order-md="last"
              order-lg="last"
              order-xl="last"
              class="pb-0 pt-0 pr-0 heightMap"
              :class="{ 'pl-0': $vuetify.breakpoint.smAndDown }"
            >
              <v-card
                class="pa-0 pt-2 d-none d-sm-flex d-sm-none d-md-flex"
                style="position: absolute; z-index: 1; margin-left: 10px; top: 65px;"
              >
                <v-card-text>
                  <v-container
                    fluid
                    class="px-7 pb-5 pt-0"
                    style="height: 50px;"
                  >
                    <v-row>
                      <v-col cols="12" class="pa-0">
                        <v-text-field
                          dense
                          id="editSearchPlace"
                          append-icon="mdi-magnify"
                        ></v-text-field>
                        <div id="edit-infowindow-content">
                          <img
                            src
                            width="16"
                            height="16"
                            id="edit-place-icon"
                          />
                          <span id="edit-place-name" class="title"></span>
                          <br />
                          <span id="edit-place-address"></span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
              <official-map ref="previewEditOutletMap"></official-map>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </mini-dialog>

    <mini-dialog
      :dialog="confirmEditOutletDialog"
      :loading="confirmLoading"
      :confirmSave="true"
      @confirmDialog="saveEditOutlet()"
      @closeDialog="closeConfirmEditOutlet()"
    ></mini-dialog>

    <!---- End of Dialog for Edit Outlet ------>

    <!---- Here is the Dialog for Delete Outlet ------>

    <mini-dialog
      :dialog="confirmDeleteOutletDialog"
      @confirmDialog="saveDeleteOutlet()"
      @closeDialog="closeConfirmDeleteOutlet()"
      :loading="confirmLoading"
    >
      <v-card-title>Are you sure you want to delete this Outlet?</v-card-title>
    </mini-dialog>

    <!---- End of Dialog for Delete Outlet ------>

    <!---- Here is the Redo Drawn Outlet ------>

    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-fab-transition>
          <v-btn
            v-if="showDrawnOutletButtons"
            style="position: absolute; z-index: 1; left: 50px; top: 10px;"
            @click="redoDrawnOutlet()"
            class="redoButtonClass"
            dark
            fab
            x-small
            color="primary"
            v-on="on"
          >
            <v-icon>mdi-redo</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Redo Drawn Outlet</span>
    </v-tooltip>

    <!---- End of Redo Drawn Outlet -------->

    <!---- Here is the View Enrolled Vehicles Dialog ------>

    <mini-dialog
      :dialog="viewEnrolledVehiclesDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >Enrolled Vehicles</span
        >
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewEnrolledVehiclesDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-container fluid>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <fab-button
              @click="openEnrollVehicles()"
              dark
              top
              xsmall
              :icon="'mdi-plus'"
              :tooltipSpan="'Enroll Vehicles'"
            ></fab-button>
            <fab-button
              @click="openEnrolledVehicles()"
              dark
              top
              xsmall
              :styleButton="'margin-left: 5px;'"
              :icon="'mdi-refresh'"
              :tooltipSpan="'Refresh'"
            ></fab-button>
          </v-row>
        </v-container>
        <v-container fill-height v-if="loadingEnrolledVehicles">
          <v-layout align-center justify-center>
            <v-progress-circular
              :size="70"
              :width="7"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-layout>
        </v-container>
        <v-data-table
          v-else
          dense
          :headers="vehiclesHeaders"
          :items="vehicles"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="vehicles.length"
        >
          <template v-if="vehicles.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + vehiclesQueryParams.offset + 1 }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ item.plate_no }}
                </td>
                <td class="text-no-wrap text-uppercase">
                  {{ item.imei_no }}
                </td>
                <td class="text-no-wrap text-uppercase text-center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="openConfirmUnassignVehicle(item)"
                        color="red"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Unassign Vehicle</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-container fluid>
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <div class="float-right">
              <p
                class="text-right grey--text caption my-2 mx-2"
                style="font-size: 14px; margin-top: 14px; display: inline-block;"
              >
                Row per Page:
              </p>
              <v-select
                style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                :items="page"
                class="grey--text mx-2"
                v-model="enrolledVehiclesPageLimit"
                @input="enrolledVehiclesLimitClick"
                dense
                height="20px"
                :disabled="this.vehicles.length < 5 ? true : false"
              ></v-select>
              <p
                class="text-center grey--text caption my-2 mx-1"
                style="font-size: 14px;display: inline-block;"
              >
                {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
              </p>
              <v-btn
                style="display: inline-block; height: 34px;"
                fab
                light
                small
                text
                @click="enrolledVehiclesBackClick"
                :disabled="disabledBack"
              >
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                style="display: inline-block; height: 34px;"
                fab
                light
                small
                text
                @click="enrolledVehiclesNextClick"
                :disabled="disabledNext"
              >
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the Enroll Vehicle Dialog ----->

    <full-width-dialog
      :dialog="fullWidthEnrollVehiclesDialog"
      :title="'Enroll Vehicle/s'"
      :color="'amber'"
      :dialogLoading="fullWidthEnrollVehiclesDialogLoading"
      :dialogLoadingColor="'amber'"
      saveButton
      dark
      @closeDialog="closeCreateDispathcDialog()"
      @saveDialog="openConfirmEnrollVehicles()"
    >
      <v-container grid-list-xs fluid>
        <v-row justify="center" class="fill-height" align-content="center">
          <v-col cols="12" sm="12" md="2" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <v-data-table
              v-model="selectedVehicles"
              :headers="vehiclesHeaders"
              :items="itemsSelectVehicles"
              class="elevation-1"
              disable-pagination
              hide-default-footer
              show-select
              :server-items-length="itemsSelectVehicles.length"
            >
              <template v-if="itemsSelectVehicles.length === 0" v-slot:no-data>
                <v-container>
                  <v-row>
                    <v-col md="6" offset-md="3">
                      <span class="title font-weight-bold"
                        >NO DATA AVAILABLE.</span
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-else v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-no-wrap text-center">
                      <v-checkbox
                        v-model="selectedVehicles"
                        class="mt-0 pt-0"
                        color="primary"
                        hide-details
                        :value="item"
                      ></v-checkbox>
                    </td>
                    <td class="text-center text-no-wrap">
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td class="text-no-wrap text-uppercase">
                      <span>{{ item.plate_no }}</span>
                    </td>
                    <td class="text-no-wrap text-uppercase">
                      <span>{{ item.imei_no }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Enroll Vehicle/s ---->

    <mini-dialog
      :dialog="confirmEnrollVehicle"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmEnrollVehicles()"
      @confirmDialog="saveEnrollVehicles()"
    ></mini-dialog>

    <!----- End of the Confirmation of Enroll Vehicle/s ------>

    <!---- Here is the Confirmation of Unassign Vehicle/s ---->

    <mini-dialog
      :dialog="confirmUnassignedVehicle"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmUnassignVehicle()"
      @confirmDialog="unassignVehicle()"
    ></mini-dialog>

    <!----- End of the Confirmation of Unassign Vehicle/s ------>
  </v-container>
</template>

<script>
import Services from "@/services/SubCompanyServices";
import DetailsContainer from "@/components/details_container/OutletDetailsContainer";

export default {
  components: { DetailsContainer },
  data: () => ({
    fab: false,
    title: "Outlets",
    items: [],
    itemsSearch: [],
    page: [
      { text: 5, value: 5 },
      { text: 10, value: 10 },
      { text: 15, value: 15 },
      { text: 20, value: 20 },
      { text: 25, value: 25 },
      { text: 30, value: 30 },
      { text: 35, value: 35 },
      { text: 40, value: 40 },
      { text: 45, value: 45 },
      { text: 50, value: 50 },
    ],
    data: {},
    queryParams: {
      search: "",
    },
    rules: {
      required: (value) => !!value || "This field is required.",
    },
    editOutletDialog: false,
    show: true,
    loadingList: false,
    addOutletDialog: false,
    confirmAddOutletDialog: false,
    confirmEditOutletDialog: false,
    confirmDeleteOutletDialog: false,
    confirmLoading: false,
    drewOutlet: [],
    finalDecisionCoords: [],
    geozoneStatus: false,
    geofenceStatus: false,
    hotspotStatus: false,
    idleThreshold: false,
    outlet_type: "",
    viewEnrolledVehiclesDialog: false,
    loadingEnrolledVehicles: false,
    vehiclesQueryParams: {
      search: "",
      limit: 5,
      offset: 0,
    },
    vehicles: [],
    vehiclesHeaders: [
      {
        text: "#",
        sortable: false,
        align: "center",
      },
      { text: "Plate #", sortable: false },
      { text: "GPS Device", sortable: false },
      { text: "Action", sortable: false, align: "center" },
    ],
    disabledNext: false,
    disabledBack: true,
    disabledLimit: true,
    totalItems: 0,
    itemFrom: 0,
    itemTo: 0,
    itemsSelectVehicles: [],
    fullWidthEnrollVehiclesDialog: false,
    fullWidthEnrollVehiclesDialogLoading: false,
    selectedVehicles: [],
    confirmEnrollVehicle: false,
    confirmUnassignedVehicle: false,
    vehicleId: [],
  }),
  created: async function() {
    if (this.$store.state.token !== null) {
      await this.initializeDataAndMap();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    selectStyle() {
      return {
        "--overflow-y": this.itemsSearch.length <= 6 ? "hidden" : "scroll",
      };
    },
    showDrawnOutletButtons() {
      if (this.$refs.map.plottedOutletModel.length !== 0) {
        return true;
      } else {
        return false;
      }
    },
    enrolledVehiclesPageLimit: {
      get() {
        return 5;
      },
      set(val) {
        this.vehiclesQueryParams.limit = val;
        this.vehiclesQueryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getDataSearch();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.search = "";
      this.queryParams.sub_company_id = this.$store.state.subcompany.id;

      try {
        // Query Data
        let response = (await Services.getOutlets(this.queryParams)).data;

        this.items = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async getDataSearch() {
      this.queryParams.company_id = this.$store.state.company.id;
      this.queryParams.sub_company_id = this.$store.state.subcompany.id;

      try {
        // Query Data
        let response = (await Services.getOutlets(this.queryParams)).data;

        this.itemsSearch = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        }
      }
    },
    async initMap() {
      await this.$refs.map.initializeMap();
      await this.$refs.map.markerCluster();
      await this.$refs.map.drawPolygon();
      await this.$refs.map.autoComplete();

      const boundsPolygon = [];
      const polygonsCoordinate = [];

      this.items.forEach(async (item) => {
        let contentString = ``;

        contentString = `${item.name.toUpperCase()}`;

        item.coordinates.forEach((datum) => {
          boundsPolygon.push({
            lat: datum.lat,
            lng: datum.lng,
          });
        });

        polygonsCoordinate.push({
          coordinates: item.coordinates,
          content: contentString,
          id: item.id,
          image_url:
            item.zone_type_stats.type_geozone == 1
              ? require("../../assets/outlet-markers/outlet_geozone.svg")
              : item.zone_type_stats.type_geofence == 1
              ? require("../../assets/outlet-markers/outlet_geofence.svg")
              : item.zone_type_stats.type_hotspot == 1
              ? require("../../assets/outlet-markers/outlet_hotspot.svg")
              : "",
        });

        const color =
          item.zone_type_stats.type_geozone == 1
            ? "#008B0D"
            : item.zone_type_stats.type_geofence == 1
            ? "#FF9800"
            : item.zone_type_stats.type_hotspot == 1
            ? "#940000"
            : "";

        await this.$refs.map.plotPolygons(item.coordinates, color);
      });

      await this.$refs.map.boundsMarkerOutlet(polygonsCoordinate);
      if (boundsPolygon.length !== 0) {
        await this.$refs.map.boundsOfAllMarkers(boundsPolygon);
      }
    },
    async initializeDataAndMap() {
      this.loadingList = true;
      await this.getDataSearch();
      await this.getData();
      await this.initMap();
      this.loadingList = false;
    },
    async clickMarker(id) {
      await this.$refs.map.clickSpecificOutlet(id);
    },
    async openOutletList() {
      this.show = true;

      this.loadingList = true;

      await this.getDataSearch();

      this.loadingList = false;
    },
    async hideOutletList() {
      this.show = false;

      this.itemsSearch.length = 0;
    },

    validateAddForm() {
      this.$refs.addDetailsContainer.validateForm();
    },
    validateEditForm() {
      this.$refs.editDetailsContainer.validateForm();
    },
    async openAddOutlet() {
      this.addOutletDialog = true;

      this.hideOutletList();

      this.drewOutlet = await this.$refs.map.plottedOutletData;

      this.data = {
        name: "",
        location: "",
        outlet_remarks: "",
        geozone_notification_status: false,
        geofence_notification_status: false,
        hotspot_notification_status: false,
      };

      this.geozoneStatus = false;
      this.geofenceStatus = false;
      this.hotspotStatus = false;
      // this.idleThreshold = false;

      await setTimeout(async () => {
        await this.$refs.previewAddOutletMap.initializeMap();
        await this.$refs.previewAddOutletMap.plotPolygons(this.drewOutlet);
        await this.$refs.previewAddOutletMap.previewDrewOutletMarker(
          this.drewOutlet
        );
        // await this.$refs.previewAddOutletMap.boundsOfAllMarkers(
        //   this.drewOutlet
        // );
      }, 1);
    },
    closeAddOutlet() {
      this.addOutletDialog = false;
      this.confirmAddOutletDialog = false;
    },
    openConfirmAddOutlet() {
      this.confirmAddOutletDialog = true;
    },
    closeConfirmAddOutlet() {
      this.confirmAddOutletDialog = false;
    },
    async openEditOutlet(data) {
      this.editOutletDialog = true;

      this.hideOutletList();

      this.data = {
        id: data.id,
        name: data.name === null ? "" : data.name.toUpperCase(),
        location: data.address ? data.address.toUpperCase() : "",
        outlet_remarks: data.outlet_remarks
          ? data.outlet_remarks.toUpperCase()
          : "",
        geozone_notification_status:
          data.zone_type_stats.geozone_notification_status === 1 ? true : false,
        geofence_notification_status:
          data.zone_type_stats.geofence_notification_status === 1
            ? true
            : false,
        hotspot_notification_status:
          data.zone_type_stats.hotspot_notification_status === 1 ? true : false,
      };

      this.geozoneStatus =
        data.zone_type_stats.type_geozone === 1 ? true : false;
      this.geofenceStatus =
        data.zone_type_stats.type_geofence === 1 ? true : false;
      this.hotspotStatus =
        data.zone_type_stats.type_hotspot === 1 ? true : false;
      // this.idleThreshold =
      //   data.zone_type_stats.idle_threshold === 1 ? true : false;

      const editStop = "edit_stop";

      setTimeout(async () => {
        await this.$refs.previewEditOutletMap.initializeMap();
        await this.$refs.previewEditOutletMap.editAutoComplete();
        await this.$refs.previewEditOutletMap.drawPolygon(editStop);
        await this.$refs.previewEditOutletMap.editPlottedOutlet(
          data.coordinates
        );
        await this.$refs.previewEditOutletMap.previewDrewOutletMarker(
          data.coordinates
        );
        // await this.$refs.previewEditOutletMap.boundsOfAllMarkers(
        //   data.coordinates
        // );
      }, 1);
    },
    redoEditDrawnOutlet() {
      this.$refs.previewEditOutletMap.removeEditDrawnPolygon();
    },
    closeConfirmEditOutlet() {
      this.confirmEditOutletDialog = false;
    },
    openConfirmEditOutlet() {
      this.confirmEditOutletDialog = true;
    },
    closeEditOutlet() {
      this.editOutletDialog = false;
      this.openOutletList();
    },
    openDeleteOutlet(id) {
      this.confirmDeleteOutletDialog = true;

      this.data.id = id;
    },
    closeConfirmDeleteOutlet() {
      this.confirmDeleteOutletDialog = false;
    },
    redoDrawnOutlet() {
      this.$refs.map.removeDrawnPolygon();
    },
    async saveAddOutlet() {
      this.confirmLoading = true;

      const data = await this.$refs.addDetailsContainer.computedData;

      let body = {
        name: data.name ? data.name.toLowerCase() : "",
        location: data.location ? data.location.toLowerCase() : "",
        outlet_remarks: data.outlet_remarks
          ? data.outlet_remarks.toLowerCase()
          : "",
        company_id: this.$store.state.company.id,
        sub_company_id: this.$store.state.subcompany.id,
        coordinates: this.drewOutlet,
        type_geozone: data.type_geozone,
        geozone_notification_status: data.geozone_notification_status,
        type_geofence: data.type_geofence,
        geofence_notification_status: data.geofence_notification_status,
        type_hotspot: data.type_hotspot,
        hotspot_notification_status: data.hotspot_notification_status,
        // idle_threshold: data.idleThresholdStats,
        // idle_threshold_value: data.idleThresholdVal,
      };

      try {
        const response = await Services.addOutlet(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAddOutlet();
          this.closeConfirmAddOutlet();
          await this.getData();
          await this.initMap();
          await this.hideOutletList();
          this.$infoBar({
            type: "success",
            text: "Outlet has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async saveEditOutlet() {
      this.confirmLoading = true;

      const data = await this.$refs.editDetailsContainer.computedData;

      const finalDecisionCoords = await this.$refs.previewEditOutletMap
        .editPlottedOutletData;

      let body = {
        name: data.name ? data.name.toLowerCase() : "",
        location: data.location ? data.location.toLowerCase() : "",
        outlet_remarks: data.outlet_remarks
          ? data.outlet_remarks.toLowerCase()
          : "",
        coordinates: finalDecisionCoords,
        type_geozone: data.type_geozone,
        geozone_notification_status: data.geozone_notification_status,
        type_geofence: data.type_geofence,
        geofence_notification_status: data.geofence_notification_status,
        type_hotspot: data.type_hotspot,
        hotspot_notification_status: data.hotspot_notification_status,
        // idle_threshold: data.idleThresholdStats,
        // idle_threshold_value: data.idleThresholdVal,
      };

      try {
        const response = await Services.editOutlet(body, data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeEditOutlet();
          this.closeConfirmEditOutlet();
          await this.getData();
          await this.initMap();
          await this.hideOutletList();
          this.$infoBar({
            type: "success",
            text: "Outlet has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async saveDeleteOutlet() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteOutlet(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeConfirmDeleteOutlet();
          await this.getData();
          await this.initMap();
          await this.hideOutletList();
          this.$infoBar({
            type: "success",
            text: "Outlet has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async filterOutlets(type) {
      this.loadingList = true;

      // this.queryParams.company_id = this.$store.state.company.id;
      this.queryParams.sub_company_id = this.$store.state.subcompany.id;
      this.queryParams.type = type;

      await this.getDataSearch();
      await this.getData();
      await this.initMap();

      this.loadingList = false;
    },
    async openEnrolledVehicles(data) {
      if (data.zone_type_stats.type_geozone == 1) {
        this.outlet_type = "GEOZONE";
      } else {
        if (data.zone_type_stats.type_geofence == 1) {
          this.outlet_type = "GEOFENCE";
        } else {
          if (data.zone_type_stats.type_hotspot == 1) {
            this.outlet_type = "HOTSPOT";
          }
        }
      }

      this.data.outlet_id = data.id;

      this.viewEnrolledVehiclesDialog = true;

      this.loadingEnrolledVehicles = true;

      this.vehicles = await this.getEnrolledVehicles();

      this.loadingEnrolledVehicles = await false;
    },
    closeViewEnrolledVehiclesDialog() {
      this.vehicles.length = 0;
      this.viewEnrolledVehiclesDialog = false;
    },
    async getEnrolledVehicles() {
      let data = null;

      this.vehiclesQueryParams.outlet_id = this.data.outlet_id;

      try {
        let response = (
          await Services.getEnrolledVehicles(this.vehiclesQueryParams)
        ).data;

        data = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom =
          this.totalItems > 0 ? this.vehiclesQueryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo =
          this.vehiclesQueryParams.offset + this.vehiclesQueryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.vehiclesQueryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async openEnrollVehicles() {
      this.fullWidthEnrollVehiclesDialog = true;
      this.fullWidthEnrollVehiclesDialogLoading = true;

      this.vehiclesQueryParams.limit = 5;
      this.vehiclesQueryParams.offset = 0;

      this.itemsSelectVehicles = await this.setEnrollVehicles();

      setTimeout(() => {
        this.fullWidthEnrollVehiclesDialogLoading = false;
      }, 600);
    },
    async enrolledVehiclesNextClick() {
      this.vehiclesQueryParams.offset += this.vehiclesQueryParams.limit;
      this.vehicles = await this.getEnrolledVehicles();
    },
    async enrolledVehiclesBackClick() {
      this.vehiclesQueryParams.offset -= this.vehiclesQueryParams.limit;
      this.vehicles = await this.getEnrolledVehicles();
    },
    async enrolledVehiclesLimitClick() {
      this.vehiclesQueryParams.offset = 0;
      this.vehicles = await this.getEnrolledVehicles();
    },
    async setEnrollVehicles() {
      let data = null;
      const body = {
        company_id: this.$store.state.company.id,
        vehicle_id: this.vehicleId,
      };

      try {
        const response = await Services.getEnrollVehicles(body);

        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async closeCreateDispathcDialog() {
      this.fullWidthEnrollVehiclesDialog = false;
      this.confirmEnrollVehicle = false;
      this.selectedVehicle.length = 0;
      this.vehicles = await this.getEnrolledVehicles();
    },
    openConfirmEnrollVehicles() {
      this.confirmEnrollVehicle = true;
    },
    closeConfirmEnrollVehicles() {
      this.confirmEnrollVehicle = false;
    },
    async saveEnrollVehicles() {
      this.confirmLoading = true;

      const body = {
        vehicles: this.selectedVehicles,
      };

      try {
        const response = await Services.enrollVehicles(
          this.data.outlet_id,
          body
        );

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeCreateDispathcDialog();
          this.$infoBar({
            type: "success",
            text: "Vehicles has been enrolled!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openConfirmUnassignVehicle(data) {
      this.data.id = data.id;
      this.data.imei_no = data.imei_no;
      this.confirmUnassignedVehicle = true;
    },
    closeConfirmUnassignVehicle() {
      this.confirmUnassignedVehicle = false;
    },
    async unassignVehicle() {
      this.confirmLoading = true;

      try {
        const response = await Services.unassignVehicle(this.data);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeConfirmUnassignVehicle();
          this.vehicles = await this.getEnrolledVehicles();

          this.$infoBar({
            type: "success",
            text: "Vehicle Unassigned!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    cutHeadings(data) {
      let text = null;
      text = data.length < 25 ? data : data.slice(0, 25) + "...";
      return text;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
.gmap-column {
  width: 80%;
}

.outlet-column {
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.box {
  height: calc(100vh - 50px);
  width: 20%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 100px;
}

.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.scrolling-wrapper {
  overflow-x: hidden;
  height: 63vh;
  overflow-y: var(--overflow-y);
  white-space: nowrap;
  padding: 0px;
}

.scrolling-wrapper-two {
  overflow-x: hidden;
  height: 450px;
  /* overflow-y: scroll; */
  white-space: nowrap;
}

#infowindow-content .title {
  font-weight: normal;
}

#infowindow-content {
  display: inline;
}

.addOutletButtonClass {
  position: absolute;
  z-index: 1;
  left: 50px;
  top: 10px;
}

.redoButtonClass {
  position: absolute;
  z-index: 1;
  left: 90px;
  top: 10px;
}

.outlet-list-card {
  width: 400px;
}

@media (max-width: 360px) and (max-height: 640px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 411px) and (max-height: 731px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 411px) and (max-height: 823px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 320px) and (max-height: 568px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 414px) and (max-height: 736px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 375px) and (max-height: 812px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media (max-width: 768px) and (max-height: 1024px) {
  .outlet-list-card {
    max-width: 90%;
  }

  .scrolling-wrapper-two {
    height: 150px;
  }

  .heightMap {
    height: 150px;
  }

  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-two-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-two-leave-active {
    /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
    transition: all 0.3s ease;
  }
  .slide-fade-two-enter, .slide-fade-two-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }

  .fade-two-enter-active,
  .fade-two-leave-active {
    transition: opacity 0.5s;
  }
  .fade-two-enter, .fade-two-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}

@media only screen and (min-width: 1100px) {
  .scrolling-wrapper {
    height: 60vh;
  }
}

@media only screen and (min-width: 1200px) {
  .scrolling-wrapper {
    height: 63vh;
  }
}

@media only screen and (min-width: 1400px) {
  .scrolling-wrapper {
    height: 67vh;
  }
}

@media only screen and (min-width: 1600px) {
  .scrolling-wrapper {
    height: 70vh;
  }
}

@media only screen and (min-width: 1700px) {
  .scrolling-wrapper {
    height: 72vh;
  }
}

@media only screen and (min-width: 1900px) {
  .scrolling-wrapper {
    height: 75vh;
  }
}

@media only screen and (min-width: 2500px) {
  .scrolling-wrapper {
    height: 80vh;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  /* transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1); */
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
