<template>
  <v-form ref="outletForm">
    <v-text-field
      v-model="data.name"
      label="Name"
      required
      :rules="[rules.required]"
    ></v-text-field>
    <v-textarea v-model="data.location" dense label="Location"></v-textarea>
    <v-textarea
      v-model="data.outlet_remarks"
      dense
      label="Outlet Remarks"
    ></v-textarea>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader class="font-weight-bold title"
        >Select Type Of Outlet:</v-subheader
      >
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="geozoneStats" color="success"></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Geozone</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="geofenceStats" color="success"></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Geofence</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="hotspotStats" color="success"></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Hotspot</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-action>
              <v-switch v-model="idleThresholdStats" color="success"></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Idle Threshold</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="data.geozone_notification_status"
                color="success"
                :disabled="!geozoneStats"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                >Notification:
                {{
                  data.geozone_notification_status ? "ON" : "OFF"
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="data.geofence_notification_status"
                color="success"
                :disabled="!geofenceStats"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                >Notification:
                {{
                  data.geofence_notification_status ? "ON" : "OFF"
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-switch
                v-model="data.hotspot_notification_status"
                color="success"
                :disabled="!hotspotStats"
              ></v-switch>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title
                >Notification:
                {{
                  data.hotspot_notification_status ? "ON" : "OFF"
                }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item>
            <v-list-item-content>
              <input
                class="inputNumber pa-1"
                v-model="idleThresholdVal"
                type="number"
                min="0"
                :disabled="!idleThresholdStats"
              />
            </v-list-item-content>
          </v-list-item> -->
        </v-col>
      </v-row>
    </v-list>
  </v-form>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    geozoneStatus: {
      type: Boolean,
      default: false,
    },
    geofenceStatus: {
      type: Boolean,
      default: false,
    },
    hotspotStatus: {
      type: Boolean,
      default: false,
    },
    // idleThresholdStatus: {
    //   type: Boolean,
    //   default: false,
    // },
    // idleThresholdValue: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      geozoneStats: false,
      geofenceStats: false,
      hotspotStats: false,
      // idleThresholdStats: false,
      // idleThresholdVal: 0,
    };
  },
  created: async function() {
    this.geozoneStats = this.geozoneStatus;
    this.geofenceStats = this.geofenceStatus;
    this.hotspotStats = this.hotspotStatus;
    // this.idleThresholdStats = this.idleThresholdStatus;
    // this.idleThresholdVal = this.idleThresholdValue;
  },
  watch: {
    geozoneStats() {
      if (this.geozoneStats) {
        this.geofenceStats = false;
        this.data.geofence_notification_status = false;
      }
    },
    geofenceStats() {
      if (this.geofenceStats) {
        this.geozoneStats = false;
        this.hotspotStats = false;
        this.data.geozone_notification_status = false;
        this.data.hotspot_notification_status = false;
      }
    },
    hotspotStats() {
      if (this.hotspotStats) {
        this.geofenceStats = false;
        this.data.geofence_notification_status = false;
      }
    },
  },
  computed: {
    computedData() {
      return {
        id: this.data.id,
        name: this.data.name,
        location: this.data.location,
        outlet_remarks: this.data.outlet_remarks,
        type_geozone: this.geozoneStats,
        geozone_notification_status: this.data.geozone_notification_status,
        type_geofence: this.geofenceStats,
        geofence_notification_status: this.data.geofence_notification_status,
        type_hotspot: this.hotspotStats,
        hotspot_notification_status: this.data.hotspot_notification_status,
        // idle_threshold: this.idleThresholdStats,
        // idle_threshold_value: this.idleThresholdVal,
      };
    },
  },
  methods: {
    validateForm() {
      if (this.$refs.outletForm.validate()) {
        this.$emit("openSaveDialog");
      }
    },
  },
};
</script>

<style scoped>
.inputNumber {
  width: 100%;
  box-sizing: border-box;
  border: 3px solid #ccc;
  border-radius: 5px;
}
</style>
